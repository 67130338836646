export default {
    name: 'mould',
    components: {
        
    },
    data () {
		return {
            activeNames: ['1'],
		}
    },
    methods: {
		
    },
    created () {
		
    }
}
  